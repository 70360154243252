import React, {
    Fragment,
    useState,
    useCallback,
    useContext,
  } from 'react'  
import { navigate } from "gatsby"
import {
    Page,
    Layout,
    Card,
    DisplayText,
    Icon,
    Stack,
    ButtonGroup,
    Button,
    TextContainer,
    TextStyle,
    FormLayout,
  } from "@shopify/polaris"
import {
    ExternalSmallMinor,
    QuestionMarkMajor
  } from '@shopify/polaris-icons';
import { doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import FirebaseContext from '../../../providers/firebase';
import styled from 'styled-components'
import CreateOfferSteps from './createOfferSteps'
const CartOfferPreviewWrapper = styled.div`
    padding: 50px 0 10px 0;
    .preview-button {
        padding: 25px 0 15px 0;
        .Polaris-Button {
            width: 485px;
        }
    }
    .instructions {
        display: flex;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        max-width: 575px;
        margin: auto;
        .instructions-image {
            margin-bottom: 20px;
            padding: 10px;
            border: 1px solid #AAAAAA;
            border-radius: 8px;
            background: #F8F8F8;
            div {
                border: 1px solid #AAAAAA;
                border-radius: 8px;
                img {
                    max-width: 550px;
                }
            }
        }
    }
    .action-buttons {
        padding: 15px 0 25px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
`
const ButtonsWrapper = styled.div`
    margin-top: 15px;
    .Polaris-ButtonGroup__Item:first-child {
        button {
            color: #008060;
            svg {
                fill: #008060;
            }
        }
    }
`
function CEOfferPreview(props) {
    const { location } = props
    const { firebase, shop, token } = useContext(FirebaseContext);
    const [active, setActive] = useState(false)
    const shopDoc = doc(firebase.firestore, 'shops', shop);
    const [shopData, shopDataLoading, shopDataError] = useDocumentData(
        shopDoc,
        {
          transform: (snap) => snap.shopData
        }
      );
    const domain = shopData && shopData.domain
    return (
        <Page 
      breadcrumbs={[{content: 'Offers', url: '/app/offers'}]}
      title={
        `Create Offer`
      }
      //primaryAction={{content: 'Save'}}
    >
        <Layout>
            <Layout.Section>
                <div style={{margin: '20px 0 10px -25px'}}>
                    <CreateOfferSteps step={3}/>
                </div>
                <Card>
                <Card.Section>
                <CartOfferPreviewWrapper>
                    <div className='instructions'>
                        <div className="instructions-image">
                            <div>
                                <img src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/ce-preview.gif?alt=media&token=981bc3e9-d03f-40fa-adcd-0e154618026e" />
                            </div>
                        </div>
                        <FormLayout>
                            <TextContainer spacing="loose">
                                <DisplayText size="medium">Last step!<br/>Enable UpsellPlus in Post-Purchase settings</DisplayText>
                                <div>
                                <TextStyle variation="subdued">Head to your Shopify Admin to enable your upsell offer. Once you have clicked “Save” in the settings, come back here to “Mark as done”.</TextStyle>
                                </div>
                            </TextContainer>
                        </FormLayout>
                    </div>
                    <div className='action-buttons'>
                        <div style={{textAlign:'center'}}>
                            <Button primary={true} onClick={
                                    () => {
                                        setActive(true)
                                        window.open(`https://${domain}/admin/settings/checkout#additionalscripts`, '_blank')
                                    }
                                }>
                                <Stack distribution="center" alignment='center' spacing='tight'>
                                <span>Go to post-purchase settings</span>
                                <Icon
                                source={ExternalSmallMinor}
                                color="base"
                                />
                            </Stack>
                            </Button>
                          </div>
                          <ButtonsWrapper>
                          <Stack distribution="center">
                                <ButtonGroup>
                                    <Button plain onClick={
                                        () => {
                                            if(window && window.$crisp){
                                                window.$crisp.push(["do", "chat:open"]);
                                            } 
                                        }
                                    }>
                                        <Stack distribution="center" alignment='center' spacing='tight'>
                                        <Icon
                                        source={QuestionMarkMajor}
                                        color="base"
                                        />
                                        <span>Get help</span>
                                        </Stack>
                                    </Button>
                                    {active ?
                                    <Button primary={true} onClick={
                                        () => {
                                            navigate('/app/offers', { replace: true,})
                                        }
                                    }>Mark as done</Button>
                                    :
                                    <Button primary disabled>
                                        Mark as done
                                    </Button>
                                }
                                </ButtonGroup>
                            </Stack>
                            </ButtonsWrapper>
                    </div>
                </CartOfferPreviewWrapper>
                </Card.Section>
                </Card>
            </Layout.Section>
        </Layout>
    </Page>
   
    )
}

export default CEOfferPreview;